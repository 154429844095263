/* src/TextBox.css */
.text-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px;
    border-radius: 10px;
  }

  .home .header {
    width: 100%;
    position: absolute;
    top: 0%;
    padding: 20px;
  }
  
  .header .title,
  .text-box .subheading,
  .text-box .download-now {
    color: var(--black); /* Base color of the text */
    text-shadow: 
      2px 2px 0 var(--white), 
      -2px -2px 0 var(--white), 
      2px -2px 0 var(--white), 
      -2px 2px 0 var(--white),
      2px 0 0 var(--white),
      -2px 0 0 var(--white),
      0 2px 0 var(--white),
      0 -2px 0 var(--white), /* White outline */
      3px 3px 5px rgba(0, 0, 0, 0.5); /* Drop shadow */
  }
  
  .title {
    display: none;
  }

  .header-logo {
    max-width: 400px;
    width: 50%;
    display: block;
    margin: auto;
  }
  
  .subheading {
    font-size: 3rem;
    font-weight: normal; /* Set font weight to normal */
    margin: 0 0 20px;
  }
  
  .download-now {
    font-size: 1rem;
    margin: 0 0 20px;
  }
  
  .app-store-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .app-store-button img {
    width: 150px; /* Adjust the size as needed */
  }
  