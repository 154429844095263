/* src/App.css */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--grey50); /* Use CSS variable */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.App {
  width: 100%;
  height: 100%;
  overflow-y: auto; /* Ensure the main container is scrollable */
}
