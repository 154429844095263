/* src/Terms.css */
.terms-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .terms-section {
    background-color: var(--grey10); /* Use CSS variable */
    padding: 40px 20px;
    flex: 1;
  }
  
  .terms-section .content {
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
    padding-top: 50px;
  }
  
  .terms-section h2, .terms-section h3 {
    margin-bottom: 20px;
  }
  
  .terms-section p {
    margin-bottom: 20px;
  }
  