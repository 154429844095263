/* src/Home.css */
.home-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure the container takes at least the full viewport height */
    overflow-y: auto; /* Allow vertical scrolling */
    
  }
  
  section {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .top-section {
    position: relative;
    width: 100%;
    height: 100vh; /* Full screen height */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--grey50); /* Use CSS variable */
  }

  .light  {
    background-color: var(--sky-grey90); /* Use CSS variable */
    color: var(--black);
  }

  .section {
    padding: 80px 20px;
  }

  .dark {
    background-color: var(--black); /* Use CSS variable */
    color: var(--white);
  }

  .content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: 400;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-weight: 400;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .feature {
    background-color: var(--white);
    border: 1px solid var(--grey90);
    border-radius: 8px;
    padding: 20px;
  }
  .testimonials-section .testimonial {
    margin-bottom: 20px;
  }
  
  .testimonials-section .testimonial h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-weight: 400;
  }
  
  .cta-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .cta-button img {
    width: 150px; /* Adjust the size as needed */
  }
  