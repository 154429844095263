@import url('https://fonts.googleapis.com/css2?family=Sniglet:wght@400;800&display=swap');

.sniglet-regular {
  font-family: "Sniglet", system-ui;
  font-weight: 400;
  font-style: normal;
}

.sniglet-extrabold {
  font-family: "Sniglet", system-ui;
  font-weight: 800;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Sniglet", system-ui;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  overflow: hidden;
  background-color: #F2F2F6;
}

#root {
  width: 100vw;
  height: 100vh;
}
