/* General Styles */
:root {
    --black: #40396E;
    --white: #FFFFFF;
    --grey10: #F0F0F0;
    --grey50: #F2F2F6;
    --grey90: #ACA9C1;
    --primary: #1C78BA;
  
    /* Burnt Toast Artist Colors */
    --electric-blue: #3F8EFC;
    --sunset-orange: #FF6B6B;
    --lemon-yellow: #F8E71C;
    --mint-green: #00C4A7;
    --lavender-purple: #A3A3E0;
    --coral-pink: #FF9AA2;
    --sky-blue: #B9E3F4;
    --peach: #FFDAB9;
    --grass-green: #7FBA00;
    --bubblegum-pink: #FF77FF;
    --turquoise: #40E0D0;
    --plum: #DDA0DD;

      /* Paler Shades */
  --paler-electric-blue: #7AB8FF;
  --paler-sunset-orange: #FF9B9B;
  --paler-lemon-yellow: #F9F5A6;
  --paler-mint-green: #9AE1D6;
  --paler-lavender-purple: #C2C2F0;
  --paler-coral-pink: #FFB5B9;
  --paler-sky-blue: #D4F0FC;
  --paler-peach: #FFE5D1;
  --paler-grass-green: #B4E28F;
  --paler-bubblegum-pink: #FF9DFF;
  --paler-turquoise: #7BE0D0;
  --paler-plum: #E6C6E0;
  }
