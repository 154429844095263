/* src/Footer.css */
.footer-section {
    width: 100%;
    background-color: var(--grey90); /* Dark grey background color */
    color: var(--black);
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    bottom: 0;
  }
  
  .content {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  .footer-columns {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-bottom: 20px;
  }
  
  .footer-column {
    flex: 1;
    max-width: 200px;
  }
  
  .footer-column h4 {
    margin-bottom: 10px;
  }
  
  .footer-column a {
    display: block;
    color: var(--black);
    text-decoration: none;
    margin: 5px 0;
  }
  
  .footer-column a:hover {
    text-decoration: underline;
  }
  
  .content a {
    color: var(--black);
    text-decoration: none;
    margin: 0 10px;
  }
  
  .content a:hover {
    text-decoration: underline;
  }
  